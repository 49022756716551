/* eslint-disable */
/* Live */
import cookie from "react-cookies";

var isSubdomain = function (url) {
  var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  return !!url.match(regex);
};

var isSubdomains = function (url) {
  return url.split(".").length > 2;
};

if (
  !window.location.host.startsWith("www") &&
  !isSubdomain(window.location.host) &&
  !isSubdomains(window.location.host)
) {
  var hostname = "www." + window.location.hostname;
} else {
  var hostname = window.location.hostname;
}

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlV4 = "https://ccpl.ninjaos.com/apiV4/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";
export const outletUrl = "https://ccpl.ninjaos.com/media/dev_team/outlet/";

var domainValues = {
  localhost: {
    appId: "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2",
    baseUrl: "http://localhost:3000/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "GERMAN BURGERGRILL",
    fbAppId: "5187619681344786",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "germanburgergrill",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "germanburgergrill",
    mapcountry: "sg",
    bgimage: "",
  },
  "germanburgergrill.com": {
    appId: "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2",
    baseUrl: "https://www.germanburgergrill.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "GERMAN BURGERGRILL",
    fbAppId: "5187619681344786",
    stripeEmail: "vignesh@jankosoft.com",
    stripeReference: "germanburgergrill",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "S$",
    defaultoutlet: 418,
    headermenu: "Header-menu-pxoa1pfjod438rhvl9jeht6ze",
    foldername: "germanburgergrill",
    mapcountry: "sg",
    bgimage: "",
  },
};

if (!domainValues[hostname]) {
  hostname = hostname.replace("www.", "");
}

export const appId = domainValues[hostname].appId;
export const stripeImage =
  "img/" + domainValues[hostname].foldername + "/stripe_logo.png";
export const productNoImg = domainValues[hostname].productNoImg;
export const companyname = domainValues[hostname].companyname;
export const fbAppId = domainValues[hostname].fbAppId;
export const stripeEmail = domainValues[hostname].stripeEmail;
export const stripeReference = domainValues[hostname].stripeReference;
export const stripeDesc = domainValues[hostname].stripeDesc;

export const defaultoutlet = domainValues[hostname].defaultoutlet;
export const headermenu = domainValues[hostname].headermenu;
export const instaToken = domainValues[hostname].instaToken;
export const foldername = domainValues[hostname].foldername;
export const mainLogo =
  "/img/" + domainValues[hostname].foldername + "/logo.png";
export const noimage =
  "/img/" + domainValues[hostname].foldername + "/no-img-product.png";
export const productImg =
  "/img/" + domainValues[hostname].foldername + "/noimg-400x400.png";
export const noimage470 =
  "/img/" + domainValues[hostname].foldername + "/noimg-470x240.jpg";
export const baseUrl = domainValues[hostname].baseUrl;

export const stripeCurrency =
  typeof cookie.load("clientCurrencyName") != "undefined"
    ? cookie.load("clientCurrencyName")
    : domainValues[hostname].stripeCurrency;
export const currencySybmol =
  typeof cookie.load("clientCurrency") != "undefined"
    ? cookie.load("clientCurrency")
    : domainValues[hostname].currencySybmol;
export const CountryTxt =
  typeof cookie.load("clientCountry") != "undefined"
    ? cookie.load("clientCountry")
    : domainValues[hostname].CountryTxt;
export const mapcountry = domainValues[hostname].mapcountry;
export const bgimage = domainValues[hostname].bgimage;
