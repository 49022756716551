/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import cookie from "react-cookies";
import axios from "axios";
import update from "immutability-helper";
import FacebookLogin from "react-facebook-login";
import { format } from "date-fns";
var dateFormat = require("dateformat");

import {
  appId,
  apiUrl,
  apiUrlV2,
  apiUrlV4,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  dineinId,
  baseUrl,
  fbAppId,
  outletUrl,
  mediaUrl,
  headermenu,
  companyname,
  mainLogo,
  productImg,
  defaultoutlet,
  currencySybmol,
  CountryTxt,
  foldername,
  mapcountry,
  bgimage,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
  GET_TABLEAVAILABILITY,
  UPDATE_CART_DETAIL,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  meridiem,
  getOrderDateTime,
  getCalculatedAmount,
} from "../Helpers/SettingHelper";
import Autocomplete from "./Autocomplete";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Guestcheckout,
} from "../../components/Myaccount/Index";

import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win-b.svg";
import warningImg from "../../common/images/warning.svg";

import avicon from "../../common/images/av-icon.png";
import loupe from "../../common/images/loupe.svg";
import loupe_dark from "../../common/images/loupe-dark.svg";
import CartSideBar from "./CartSideBar";

import fbscootersvg from "../../common/images/delivery-bike.svg";
import offline from "../../common/images/offline.png";
import fbscooter from "../../common/images/delivery-bike.svg";
import fbscooterw from "../../common/images/delivery-bike-white.svg";
import takeawayImg from "../../common/images/takeaway.svg";
import takeawaywImg from "../../common/images/takeaway-white.svg";
import dineImg from "../../common/images/dinein.svg";
import dinewImg from "../../common/images/dinein-white.svg";
import calenderImg from "../../common/images/reservation.svg";
import calenderwImg from "../../common/images/reservation-white.svg";
import menuImg from "../../common/images/fb-menu.png";
import qrImg from "../../common/images/qr-code.png";
import cateringImg from "../../common/images/catering.svg";
import cateringwImg from "../../common/images/catering-white.svg";
import shoppingBag from "../../common/images/shopping-bag.svg";
import cartLike from "../../common/images/heart-white.svg";
import cartLikeDark from "../../common/images/heart-black.svg";
import cartLikeActive from "../../common/images/heart-red.svg";

import qrbannerImg from "../../common/images/smart-phone.png";

import qrnewImg from "../../common/images/qrnew.png";
import qroldImg from "../../common/images/qrold.png";

import TagManager from "react-gtm-module";
import { loadAnalytics } from "./withTracker";
import ReactPixel from "react-facebook-pixel";
import { object } from "underscore";
var qs = require("qs");
class Header extends Component {
  constructor(props) {
    super(props);
    var defaultAvilablityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";

    var servicesurchargeArr = Array();
    servicesurchargeArr["servicechrg_displaylabel"] = "Service Charge";
    servicesurchargeArr["servicechrg_type"] = "percentage";
    servicesurchargeArr["servicechrg_per"] = 0;
    servicesurchargeArr["servicechrg_amount"] = 0;
    servicesurchargeArr["surcharge_amount"] = 0;
    servicesurchargeArr["surcharge_type"] = "";
    servicesurchargeArr["surcharge_date"] = "";
    servicesurchargeArr["surcharge_frmtime"] = "";
    servicesurchargeArr["surcharge_totime"] = "";

    this.state = {
      seletedAvilablityId: defaultAvilablityId,
      defaultAvilablityId: defaultAvilablityId,
      seletedOutletId: "",
      setAvilablityId: defaultAvilablityId,
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      orderHandled: "",
      orderDeliveryAddress: "",
      orderDeliveryPostalCode: "",
      nextavail: "",
      logindata: "",
      deliveryInfo: [],
      pickupInfo: [],

      /* For Advanced Slot Start */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */
      fields: {
        email: "",
        pin: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
        check_ref_code: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        pin: "",
        rePin: "",
        mobile: "",
        pdpa_consent: "",
        terms: "Y",
        terms1: "Y",
        completeReg: "N",
        check_ref_code:
          cookie.load("referCode") !== "" &&
          typeof cookie.load("referCode") !== undefined &&
          typeof cookie.load("referCode") !== "undefined"
            ? cookie.load("referCode")
            : "",
      },
      menuData: [],
      popupOutletID: "",
      popupOutletDetails: "",
      error_order_from: "",
      error_order_type: "",
      error_pickup_outlet: "",
      switchmsg: "",
      fbstatus: "ok",
      dine_in_click: "No",
      table_number: "",
      openAddress: false,
      deliveryAddress: "",
      deliveryLat: "",
      deliveryLon: "",

      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      cartTotalItmCount: 0,
      servicesurchargeval: servicesurchargeArr,
      globalSettings: [],
      startclicksearch: false,
    };

    this.props.getGlobalSettings();
    this.props.getPickupOutlets();
    // this.props.getAllOutlets(deliveryId);
    this.props.getAllOutlets();
    this.props.getSecondaryAddress();

    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;

    var intRegex = /[0-9 -()+]+$/;
    if (intRegex.test(formPayload.email)) {
      var postObject = {
        app_id: appId,
        type: "web",
        logintype: "mobile",
        passwordtype: "pin",
        login_username: formPayload.email,
        login_password: formPayload.pin,
      };
    } else {
      var postObject = {
        app_id: appId,
        type: "web",
        logintype: "email",
        passwordtype: "pin",
        login_username: formPayload.email,
        login_password: formPayload.pin,
      };
    }

    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "terms1") {
      value = $("#terms1").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }

    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var postObject = {
      app_id: appId,
      type: "web",
      registertype: "mobile",
      passwordtype: "pin",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_pin: formPayload.pin,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      customer_newsletter_enable: formPayload.terms1 === "Y" ? "yes" : "no",
      site_url: this.props.globalsettings[0].result_set.client_site_url,
      customer_ref_code: formPayload.check_ref_code,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    const formPayload = this.state.fieldscheckout;
    if (
      document.getElementById("spn-email-error").innerHTML ===
      '<span class="error">This email already exists</span>'
    ) {
      return false;
    }
    if (
      document.getElementById("spn-mobile-error").innerHTML ===
      '<span class="error">This mobile number already exists</span>'
    ) {
      return false;
    }

    showLoader("login_in_guest", "class");

    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
      customer_ref_code: formPayload.check_ref_code,
    };

    axios
      .post(apiUrl + "guestaccount/create", qs.stringify(postObject))
      .then((response) => {
        hideLoader("login_in_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 1, { path: "/" });
          const { history } = this.props;
          var postObject = {
            app_id: appId,
            reference_id: getReferenceID(),
            customer_id: response.data.result_set.customer_id,
            availability_id:
              cookie.load("defaultAvilablityId") !== "" &&
              typeof cookie.load("defaultAvilablityId") !== undefined &&
              typeof cookie.load("defaultAvilablityId") !== "undefined"
                ? cookie.load("defaultAvilablityId")
                : "",
            login_type: "1",
          };

          axios
            .post(
              apiUrl + "cart/update_customer_info",
              qs.stringify(postObject)
            )
            .then((res) => {
              showAlert("Success", "Logged in Successfully!");
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              if (res.data.status === "ok") {
                if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                  cookie.remove("loginpopupTrigger", { path: "/" });
                  history.push("/checkout");
                } else {
                  history.push("/myaccount");
                }
              } else {
                if (cookie.load("redirectReservatin") === "Yes") {
                  cookie.save("defaultAvilablityId", reservationId, {
                    path: "/",
                  });
                  cookie.remove("redirectReservatin", { path: "/" });
                  history.push("/reservation");
                } else {
                  history.push("/myaccount");
                }
              }
            });
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleCheckoutSkip = () => {
    showLoader("login_in_skip_guest", "class");
    axios
      .get(
        apiUrlV4 + "anonymouscustomer/get_anonymous_customer?app_id=" + appId
      )
      .then((response) => {
        hideLoader("login_in_skip_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 2, { path: "/" });
          if (cookie.load("defaultAvilablityId") === dineinId) {
            cookie.save("continuetochekout", "Yes", { path: "/" });
          }
          const { history } = this.props;
          var postObject = {
            app_id: appId,
            reference_id: getReferenceID(),
            customer_id: response.data.result_set.customer_id,
            availability_id:
              cookie.load("defaultAvilablityId") !== "" &&
              typeof cookie.load("defaultAvilablityId") !== undefined &&
              typeof cookie.load("defaultAvilablityId") !== "undefined"
                ? cookie.load("defaultAvilablityId")
                : "",
            login_type: "2",
          };

          axios
            .post(
              apiUrl + "cart/update_customer_info",
              qs.stringify(postObject)
            )
            .then((res) => {
              this.postOrder(1, "Yes");
            });
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState({
        pickupOutlets: PropsDt.outletslist,
        pickupOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    // if (PropsDt.fbloginData !== this.props.fbloginData) {
    //   this.doLogin(PropsDt.fbloginData);
    // }

    if (PropsDt.tableAvailability !== this.props.tableAvailability) {
      if (PropsDt.tableAvailability.status) {
        if (PropsDt.tableAvailability.status == "ok") {
          this.dineinOutlet(PropsDt.tableAvailability.result_set[0]);
        } else {
          let res_obj = {
            status: PropsDt.tableAvailability.status,
            message: PropsDt.tableAvailability.result_set,
          };
          this.showMessage(res_obj);
          hideLoader("dinein_popup_item", "class");
        }
      }
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup;
        this.setState(
          {
            regstatus: "ok",
            fieldssignup: {
              firstname: "",
              email: "",
              pin: "",
              rePin: "",
              mobile: "",
              pdpa_consent: "",
              terms: "Y",
              terms1: "Y",
              completeReg: "Y",
              check_ref_code: "",
            },
          },
          function () {
            const script4 = document.createElement("script");
            script4.type = "text/javascript";
            script4.async = true;
            script4.id = "registertag";
            script4.innerHTML =
              "function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-10862588258/D5hUCN6L9bYDEOLi17so', 'event_callback': callback }); return false; }";
            document.head.appendChild(script4);
          }
        );
        this.showMessage(PropsDt.registration[0]);
      }
    }

    if (this.state.overAllcart !== PropsDt.overAllcart) {
      this.setState({
        overAllcart: PropsDt.overAllcart,
        cartItems: PropsDt.cartItems,
        cartDetails: PropsDt.cartDetails,
        cartStatus: PropsDt.cartStatus,
        cartTotalItmCount: PropsDt.cartTotalItmCount,
        product_lead_time: PropsDt.product_lead_time,
      });
    }

    if (this.state.globalSettings !== PropsDt.globalsettings) {
      var tampStArr = PropsDt.globalsettings;

      if (Object.keys(tampStArr).length > 0) {
        if (PropsDt.globalsettings[0].status === "ok") {
          var globalSettings = PropsDt.globalsettings[0].result_set;
          this.setState({
            globalSettings: globalSettings,
          });
        }
      }
    }
  }

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link
          to={
            menu.nav_title_slug !== "" && menu.nav_title_slug !== null
              ? "/page/" + menu.nav_title_slug
              : "#"
          }
          className="sub_menu_list"
          title={menu.nav_title}
        >
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.openCategoryNav.bind(this, menu.nav_pages)}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu) {
    if (menu.nav_type === "0") {
      return (
        <Link to={"/page/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.openCategoryNav.bind(this, menu.nav_pages)}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "menu") {
      returnClsTx =
        this.props.match.path === "/menu" ||
        this.props.match.path === "/menu/:slugType/:slugValue" ||
        this.props.match.path === "/menu/:slugType/:slugValue/:proValue"
          ? "active"
          : "";
    } else if (nav_pages === "about-us") {
      returnClsTx =
        this.props.match.path === "/about-us" ||
        this.props.match.path === "/our-story" ||
        this.props.match.path === "/locations" ||
        this.props.match.path === "/contact-us"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : "";
    }
    return returnClsTx;
  }
  openCategoryNav(pageLink, event) {
    if (pageLink === "products") {
      if (
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
      ) {
        if (cookie.load("defaultAvilablityId") === cateringId) {
          event.preventDefault();
          if ($(".mobile-menu.active").length > 0) {
            $("#close_mobile_menu").trigger("click");
          }
          $("html, body").animate(
            {
              scrollTop: $(".home-options-tab").offset().top - 100,
            },
            500
          );
        }
      }
    }
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        return (
          <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
            {this.createLink(menu)}
            {menu.sub_menu.length > 0 && (
              <>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="submenu-arow disbl_href_action"
                ></a>
                <ul className="submenu_list">
                  {menu.sub_menu.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          this.props.match.path === "/" + item.nav_pages
                            ? "active sub_menu_list"
                            : "sub_menu_list"
                        }
                      >
                        {this.createSubmenu(item)}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */
  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  setAvailabilityFun(availability, event) {
    event.preventDefault();
    if (availability === cateringId) {
      return false;
    }
    this.setState(
      {
        setAvilablityId: availability,
        error_order_type: "",
        openAddress: true,
      },
      () => {
        this.chooseAvailabilityFun(availability, event);
      }
    );
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }
    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/package");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
    } else if (availability === dineinId) {
      cookie.save("defaultAvilablityId", dineinId, { path: "/" });
      $.magnificPopup.close();
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityMbl() {
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "Order Now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Takeaway";
    } else if (availability === dineinId) {
      showtext = "Dine In";
    } else if (availability === cateringId) {
      showtext = "Catering";
      // } else if (availability === reservationId) {
    } else if (
      this.props.match.path === "/reservation" ||
      this.props.match.path === "/reservation-summary" ||
      this.props.match.path === "/reservation-thankyou"
    ) {
      showtext = "Reservation";
    }
    return showtext;
  }

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      const { history } = this.props;

      if (cookie.load("cateringPay") === "start") {
        showAlert("Success", "Logged in Successfully!");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });

        history.push(
          "/payment/" +
            cookie.load("orderIdTxt") +
            "/" +
            cookie.load("paymentIdTxt")
        );
        /*history.push("/payment");*/
      } else {
        var userAccountType =
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType");
        var postObject = {
          app_id: appId,
          reference_id: getReferenceID(),
          customer_id: fbloginData.result_set.customer_id,
          availability_id: cookie.load("defaultAvilablityId"),
          login_type: userAccountType,
        };

        axios
          .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
          .then((res) => {
            showAlert("Success", "Logged in Successfully!");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });

            if (res.data.status === "ok") {
              if (mobileno === "") {
                showAlert(
                  "Error",
                  "Please update the Mobile Number to continue booking"
                );
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
                history.push("/myaccount");
              } else if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                cookie.remove("loginpopupTrigger", { path: "/" });
                history.push("/checkout");
              } else if (
                cookie.load("loginpopupTrigger") === "fromchecatering"
              ) {
                cookie.remove("loginpopupTrigger", { path: "/" });
                history.push("/package/checkout");
              } else if (cookie.load("redirectReservatin") === "Yes") {
                cookie.save("defaultAvilablityId", reservationId, {
                  path: "/",
                });
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
              } else {
                history.push("/myaccount");
              }
            } else {
              if (cookie.load("redirectReservatin") === "Yes") {
                cookie.save("defaultAvilablityId", reservationId, {
                  path: "/",
                });
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
              } else {
                history.push("/myaccount");
              }
            }
          });
      }
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      /*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  closepopup(event) {
    event.preventDefault();
    hideLoader("dinein_popup_item", "class");
    if (this.props.match.path === "/checkout") {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
    } else {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    });
    /* For Advanced Slot End */

    this.destroyCart("Yes");

    var nextavail = this.state.nextavail;

    var popupIdtxt = "";
    if (nextavail === deliveryId) {
      popupIdtxt = "#delevery-postcode-popup";
    } else if (nextavail === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    } else if (nextavail === dineinId) {
      popupIdtxt = "#dinein-popup";
    } else if (nextavail === reservationId) {
      $.magnificPopup.close();
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      this.props.history.push("/reservation");
    } else {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
      cookie.remove("outletchosen", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    // cookie.remove("defaultAvilablityId", { path: "/" });
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    /* For Advanced Slot */
    cookie.remove("isAdvanced", { path: "/" });
    cookie.remove("slotType", { path: "/" });
    cookie.remove("orderSlotVal", { path: "/" });
    cookie.remove("orderSlotTxt", { path: "/" });
    cookie.remove("orderSlotStrTime", { path: "/" });
    cookie.remove("orderSlotEndTime", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    var outletIdTxt = this.state.delivery_outlet_id;

    /*  if (outletIdTxt === "") {
      $(".postal_error").html(
        '<span class="error">Go Back and Select your delivery outlet.</span>'
      );
      return false;
    } */

    if (mapcountry === "sg") {
      if (postalcode.length < 3) {
        $(".postal_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }
    } else {
      if (this.state.deliveryLat === "" || this.state.deliveryLon === "") {
        $("#location-error").show().html("Please enter your delivery address.");
        return false;
      }
    }

    showLoader("delivery_submit_cls", "class");
    var zonecon = "";
    if (mapcountry === "sg") {
      zonecon = "&postal_code=" + postalcode;
    } else {
      zonecon =
        "&address_based=yes&latitude=" +
        this.state.deliveryLat +
        "&longitude=" +
        this.state.deliveryLon;
    }
    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            zonecon +
            "&postalcode_basedoutlet=yes"
        ) /* ,
        axios.get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&outletId=" +
            outletIdTxt
        ), */,
      ])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, { path: "/" });
            var postalCode = "";
            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );
            if (mapcountry === "sg") {
              var orderDeliveryAddress =
                res.data.result_set.postal_code_information.zip_buno +
                " " +
                res.data.result_set.postal_code_information.zip_sname;
              postalCode = res.data.result_set.postal_code_information.zip_code;
              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                " " +
                CountryTxt +
                " " +
                res.data.result_set.outlet_postal_code;
            } else {
              var orderDeliveryAddress = this.state.deliveryAddress;
              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                CountryTxt;
            }

            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              res.data.result_set.outlet_delivery_timing +
              " Minutes";

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] = postalCode;
            deliveryInfo["orderTAT"] =
              res.data.result_set.outlet_delivery_timing;
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = availability;

            deliveryInfo["orderHandledByText"] = orderHandledText;

            this.setState({
              deliveryInfo: deliveryInfo,
              seletedAvilablityId: availability,
              seletedOutletId: res.data.result_set.outlet_id,
              order_tat_time: res.data.result_set.outlet_delivery_timing,
              orderHandled: orderHandled,
              orderDeliveryAddress: orderDeliveryAddress,
              orderDeliveryPostalCode: postalCode,
            });
            /*  +
                CountryTxt + " " +
                res.data.result_set.postal_code_information.zip_code */

            /* Success time slot response */
            axios
              .get(
                apiUrlV2 +
                  "settings/chkTimeslotIsAvaiable?app_id=" +
                  appId +
                  "&availability_id=" +
                  availability +
                  "&outletId=" +
                  res.data.result_set.outlet_id
              )
              .then((timeslt) => {
                hideLoader("delivery_submit_cls", "class");
                if (timeslt.data.status === "success") {
                  /* For Advanced Slot */
                  var isAdvanced = timeslt.data.isAdvanced,
                    slotType = "0";
                  if (isAdvanced === "yes") {
                    slotType = timeslt.data.slotType;
                  }
                  this.setState({
                    getDateTimeFlg: "yes",
                    isAdvanced: isAdvanced,
                    slotType: slotType,
                  });

                  removeOrderDateTime();
                  removePromoCkValue();
                  $.magnificPopup.close();
                  $.magnificPopup.open({
                    items: {
                      src: "#awesome-popup",
                    },
                    type: "inline",
                  });
                } else {
                  if (mapcountry === "sg") {
                    $.magnificPopup.close();
                    $.magnificPopup.open({
                      items: {
                        src: "#outlet-error-popup",
                      },
                      type: "inline",
                    });
                  } else {
                    $("#location-error")
                      .show()
                      .html("Please enter your delivery address.");
                  }
                }
              });
          }

          /* Error response */
          if (res.data.status === "error") {
            hideLoader("delivery_submit_cls", "class");

            this.setState({ deliveryInfo: deliveryInfo });
            if (mapcountry === "sg") {
              $.magnificPopup.close();
              $.magnificPopup.open({
                items: {
                  src: "#error-postal-popup",
                },
                type: "inline",
              });

              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            } else {
              if (
                appId === "93EDF976-7242-4864-B57E-7B49306521AC" ||
                appId === "E66D5AAF-9077-4885-B379-02D3FCB8BB16"
              ) {
                $("#location-error")
                  .show()
                  .html("We currently do not cater your location");
              } else {
                $("#location-error").show().html("We cant find your address");
              }
            }
          }
        })
      );
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 3) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          cookie.save("outletchosen", availability, { path: "/" });
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              CountryTxt +
              " " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });
          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          if (mapcountry === "sg") {
            cookie.save(
              "orderPostalCode",
              res.data.result_set.postal_code_information.zip_code,
              { path: "/" }
            );
          }
          cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
            path: "/",
          });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", " +
            CountryTxt +
            " " +
            res.data.result_set.outlet_postal_code;
          cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      cookie.save("outletchosen", cookie.load("defaultAvilablityId"), {
        path: "/",
      });
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        window.location.href = "/";
      } else {
        this.props.history.push("/products");
      }
    }
  }

  selectDatetm() {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) {
      // showLoader("takeaway-btn-act", "class");
      showLoader("self_pop_col", "class");

      axios
        .get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            pickupId +
            "&outletId=" +
            seletedOutletId
        )
        .then((res) => {
          // hideLoader("takeaway-btn-act", "class");
          hideLoader("self_pop_col", "class");

          /* Success response */
          if (res.data.status === "success") {
            /* For Advanced Slot */
            var isAdvanced = res.data.isAdvanced,
              slotType = "0";
            if (isAdvanced === "yes") {
              slotType = res.data.slotType;
            }
            this.setState({
              getDateTimeFlg: "yes",
              isAdvanced: isAdvanced,
              slotType: slotType,
            });

            removeOrderDateTime();
            removePromoCkValue();

            $.magnificPopup.open({
              items: {
                src: "#awesome-popup",
              },
              type: "inline",
            });
          } else {
            $.magnificPopup.open({
              items: {
                src: "#outlet-error-popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    }
  }

  gobackOrderNow(e) {
    e.preventDefault();
    $.magnificPopup.close();
    $(".search_outlet").val("");
    var goback = "#order-popup";

    $.magnificPopup.open({
      items: {
        src: goback,
      },
      type: "inline",
    });
  }

  gobackOrderFrom(e) {
    e.preventDefault();
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        if (
          stripslashes(item.outlet_name).toLowerCase().indexOf(value) >= 0 ||
          stripslashes(item.outlet_address_line1)
            .toLowerCase()
            .indexOf(value) >= 0 ||
          stripslashes(item.outlet_postal_code).indexOf(value) >= 0
        ) {
          return item;
        }
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => {
        return (
          <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
            <span
              onClick={this.setPickupOutletFun.bind(
                this,
                loaddata,
                loaddata.outlet_id
              )}
            >
              <div className="outlet-list-lhs">
                {loaddata.outlet_image !== "" ? (
                  <img
                    src={outletUrl + loaddata.outlet_image}
                    alt={stripslashes(loaddata.outlet_name)}
                  />
                ) : (
                  <img
                    src={productImg}
                    alt={stripslashes(loaddata.outlet_name)}
                  />
                )}
              </div>
              <div className="outlet-list-rhs">
                <h4 className="outlet-list-name">
                  {stripslashes(loaddata.outlet_name)}
                </h4>
                <p className="outlet-list-address">
                  {loaddata.outlet_address_line1},{" "}
                  {this.showUnitNum(
                    loaddata.outlet_unit_number1,
                    loaddata.outlet_unit_number2
                  )}
                  {CountryTxt} {loaddata.outlet_postal_code}
                </p>
                <ul>
                  <li className="otulet-waiting">
                    {this.state.seletedAvilablityId === deliveryId &&
                      loaddata.outlet_delivery_tat > 0 && (
                        <span>
                          Waiting : {loaddata.outlet_delivery_tat} min
                        </span>
                      )}
                    {this.state.seletedAvilablityId != deliveryId &&
                      loaddata.outlet_pickup_tat > 0 && (
                        <span>Waiting : {loaddata.outlet_pickup_tat} min</span>
                      )}
                  </li>
                  <li className="otulet-direction">
                    {loaddata.outlet_map_link != "" && (
                      <span>
                        <a
                          href={loaddata.outlet_map_link}
                          target="_blank"
                          onClick={this.openMapLink.bind(
                            this,
                            loaddata.outlet_map_link
                          )}
                        >
                          Direction
                        </a>
                      </span>
                    )}
                  </li>
                  {/* {loaddata.da_monday_start_time !== "" &&
                      loaddata.da_monday_start_time !== null && (
                        <li className="otulet-timing">
                          <span>
                            Operations:{" "}
                            {meridiem(loaddata.da_monday_start_time)} -{" "}
                            {meridiem(loaddata.da_monday_end_time)}
                          </span>
                        </li>
                      )} */}
                </ul>
              </div>
            </span>
          </li>
        );
      });
    } else {
      return (
        <li>
          <a>
            {appId === "9648B00B-467E-4AF0-A03B-014C81945EE5"
              ? "The outlet is currently not available"
              : "No Outlet found"}
          </a>
        </li>
      );
    }
  }

  /* load outlets  */
  loadMenuOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      var existOutlet = [];
      return this.state.deliveryOutlets.map((loaddata, index) => {
        if (
          loaddata.outlet_id !== "321" &&
          existOutlet.indexOf(loaddata.outlet_id) < 0 &&
          loaddata.outlet_menu_pdf
        ) {
          existOutlet.push(loaddata.outlet_id);
          return (
            <li key={index}>
              <span
                onClick={this.downalodPDF.bind(this, loaddata.outlet_menu_pdf)}
              >
                <div className="outlet-list-lhs">
                  <img
                    src={outletUrl + loaddata.outlet_image}
                    alt={stripslashes(loaddata.outlet_name)}
                  />
                </div>
                <div className="outlet-list-rhs">
                  <h4 className="outlet-list-name">
                    {stripslashes(loaddata.outlet_name)}
                  </h4>
                  <p className="outlet-list-address">
                    {loaddata.outlet_address_line1},{" "}
                    {this.showUnitNum(
                      loaddata.outlet_unit_number1,
                      loaddata.outlet_unit_number2
                    )}
                    {CountryTxt} {loaddata.outlet_postal_code}
                  </p>
                  <ul>
                    <li className="otulet-waiting">
                      {this.state.seletedAvilablityId === deliveryId &&
                        loaddata.outlet_delivery_tat > 0 && (
                          <span>
                            Waiting : {loaddata.outlet_delivery_tat} min
                          </span>
                        )}
                      {this.state.seletedAvilablityId != deliveryId &&
                        loaddata.outlet_pickup_tat > 0 && (
                          <span>
                            Waiting : {loaddata.outlet_pickup_tat} min
                          </span>
                        )}
                    </li>
                    <li className="otulet-direction">
                      {loaddata.outlet_map_link != "" && (
                        <span>
                          <a
                            href={loaddata.outlet_map_link}
                            target="_blank"
                            onClick={this.openMapLink.bind(
                              this,
                              loaddata.outlet_map_link
                            )}
                          >
                            Direction
                          </a>
                        </span>
                      )}
                    </li>
                    {/* {loaddata.da_monday_start_time !== "" &&
                      loaddata.da_monday_start_time !== null && (
                        <li className="otulet-timing">
                          <span>
                            Operations:{" "}
                            {meridiem(loaddata.da_monday_start_time)} -{" "}
                            {meridiem(loaddata.da_monday_end_time)}
                          </span>
                        </li>
                      )} */}
                  </ul>
                </div>
              </span>
            </li>
          );
        }
      });
    } else {
      return (
        <li>
          <a>
            {appId === "9648B00B-467E-4AF0-A03B-014C81945EE5"
              ? "The outlet is currently not available"
              : "No Outlet found"}
          </a>
        </li>
      );
    }
  }

  downalodPDF(link, e) {
    e.stopPropagation();
    e.preventDefault();
    window.open(mediaUrl + "outlet/" + link, "_blank");
  }

  setPickupOutletFun(outletDetails, outletID) {
    this.setState({
      seletedOutletId: outletID,
      popupOutletDetails: outletDetails,
      error_pickup_outlet: "",
    });

    this.pickOutlet(outletDetails, "");
  }

  openMapLink(link, e) {
    e.stopPropagation();
    e.preventDefault();
    window.open(link, "_blank");
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;

    var pickupInfo = this.state.pickupInfo;
    /* &&
      Object.keys(pickupInfo).length > 0 */
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) ? "active" : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo + "," : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            {CountryTxt} {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>
            {appId === "9648B00B-467E-4AF0-A03B-014C81945EE5"
              ? "The outlet is currently not available"
              : "No Outlet found"}
          </a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    // event.preventDefault();
    if (loaddata === "") {
      this.setState({ error_pickup_outlet: 1 });
      return false;
    } else {
      this.setState({ error_pickup_outlet: "" });
    }

    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      CountryTxt +
      " " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidMount() {
    if (
      this.props.match.path ===
      "/menu/:slugType/:slugValue/:proValue/:tableNumber"
    ) {
      if (
        this.props.match.params.tableNumber !== "" &&
        typeof this.props.match.params.tableNumber !== undefined &&
        typeof this.props.match.params.tableNumber !== "undefined"
      ) {
        this.setState(
          { table_number: this.props.match.params.tableNumber },
          function () {
            this.findTable();
          }
        );
      }
    }

    if (bgimage !== "") {
      $("body").css("background-image", "url(/img/bg/" + bgimage + ")");
      $(window).scroll(function () {
        $("body").css(
          "background-position",
          "50% " + $(this).scrollTop() / 2 + "px"
        );
      });
    }

    setInterval(function () {
      if ($(document).find(".hcategory_menu").hasClass("open")) {
        $("body").addClass("category_open");
      } else {
        $("body").removeClass("category_open");
      }
    }, 100);

    loadAnalytics();
    if (this.props.match.path === "/checkout") {
      if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
        const script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.async = true;
        script3.id = "checkouttag";
        script3.innerHTML =
          "gtag('event', 'conversion', {  'send_to': 'AW-10862588258/YfG1CMD1ibcDEOLi17so', 'transaction_id': ''});";
        document.head.appendChild(script3);
      }
    } else {
      var element = document.getElementById("checkouttag");
      if (element !== null) {
        element.parentNode.removeChild(element);
      }
    }
    if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
      if (this.state.defaultAvilablityId === pickupId) {
        const script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.async = true;
        script3.id = "pickuptag";
        script3.innerHTML =
          "function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-10862588258/D5hUCN6L9bYDEOLi17so', 'event_callback': callback }); return false; } ";
        document.head.appendChild(script3);
      } else {
        var element1 = document.getElementById("pickuptag");
        if (element1 !== null) {
          element1.parentNode.removeChild(element1);
        }
      }
      var element2 = document.getElementById("registertag");
      if (element2 !== null) {
        element2.parentNode.removeChild(element2);
      }
    }
    if (mapcountry !== "sg") {
      var element5 = document.getElementById("googlemap");
      if (element5 === null) {
        const script6 = document.createElement("script");
        script6.id = "googlemap";
        script6.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdP-_7USWtwCr6L0HFIHEJqUZJmea9c4E&libraries=places";
        script6.async = true;
        document.head.appendChild(script6);
      }
    }

    /*setInterval(function(){
        $(document).find('html').css('overflow','initial');
      },100)*/

    axios
      .get(apiUrl + "settings/checkPaymentStatus?app_id=" + appId)
      .then((res) => {
        if (
          Object.values(res.data) == "fail" &&
          typeof Object.values(res.data) != "undefined" &&
          Object.values(res.data) != ""
        ) {
          $.magnificPopup.open({
            items: {
              src: "#offline-popup",
            },
            type: "inline",
            showCloseBtn: false,
            midClick: true,
            closeOnBgClick: false,
          });
        }
      });

    var currentThisVal = this;
    $(document)
      .off("click", ".clear_all_values")
      .on("click", ".clear_all_values", function (e) {
        /* "Yes" */
        currentThisVal.changeAvailability();
      });
    $(document).find("title").html(companyname);

    setTimeout(
      function () {
        var settingsArr = this.props.globalsettings;
        if (Object.keys(settingsArr).length > 0) {
          var client_theme = settingsArr[0].result_set.client_theme;
          if (client_theme == 1) {
            $(document).find("body").addClass("dark-theme");
          }

          var clientCurrency = "S$";
          var clientCurrencyName = "SGD";
          var clientCountry = "Singapore";
          var client_currency =
            this.props.globalsettings[0].result_set.client_currency;
          var client_currency_name =
            this.props.globalsettings[0].result_set.client_currency_name;
          var client_country =
            this.props.globalsettings[0].result_set.client_country;
          if (
            client_currency !== "" &&
            client_currency !== null &&
            client_currency !== "S$"
          ) {
            clientCurrency = client_currency;
          }
          if (client_currency_name !== "" && client_currency_name !== null) {
            clientCurrencyName = client_currency_name;
          }
          if (client_country !== "" && client_country !== null) {
            clientCountry = client_country;
          }
          cookie.save("clientCurrency", clientCurrency);
          cookie.save("clientCurrencyName", clientCurrencyName);
          cookie.save("clientCountry", clientCountry);
          if (currencySybmol != clientCurrency) {
            window.location.reload();
          }
        }
      }.bind(this),
      1000
    );

    $(document)
      .off("click", ".sub_menu_list")
      .on("click", ".sub_menu_list", function (e) {
        $("#close_mobile_menu").trigger("click");
      });

    if (
      cookie.load("openLogin") !== undefined &&
      typeof cookie.load("openLogin") !== undefined &&
      typeof cookie.load("openLogin") !== "undefined"
    ) {
      cookie.remove("openLogin", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (
      cookie.load("openWarningPopup") !== undefined &&
      typeof cookie.load("openWarningPopup") !== undefined &&
      typeof cookie.load("openWarningPopup") !== "undefined" &&
      this.props.match.path === "/"
    ) {
      cookie.remove("openWarningPopup", { path: "/" });

      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
    }

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });

    $(document).click(function (e) {
      if ($(e.target).is(".open_qr")) {
        $.magnificPopup.open({
          items: {
            src: "#qr-popup",
          },
          type: "inline",
        });
      }
    });

    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    var headermenus = "";
    setTimeout(
      function () {
        var settingsArr = this.props.globalsettings;
        if (Object.keys(settingsArr).length > 0) {
          var navmenus = settingsArr[0].result_set.navmenus;
          navmenus.map((data) => {
            if (data.menu_title === "Header Menu") {
              var headermenus = data.menu_slug;
              this.props.getMenuData(headermenus);
            }
          });
        }
      }.bind(this),
      1000
    );

    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    $("body").on("click", ".hsearch_trigger", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".search_result").hide();
      $(this).toggleClass("active");
      $(".hsearch_sec").toggleClass("open");
      $(".hsearch_sec .form-control").focus();
      if (
        currentThisVal.state.searchProResult.length === 0 &&
        currentThisVal.props.match.path !== "/menu" &&
        currentThisVal.state.startclicksearch === false
      ) {
        currentThisVal.setState({ startclicksearch: true }, function () {
          currentThisVal.getSearchProductList();
        });
      }
    });

    $(document).click(function (e) {
      if ($(".hsearch_trigger").length > 0) {
        if (
          !$(e.target).is(
            ".hsearch_trigger, .hsearch_sec, .hsearch_sec *, .htico_search, .htico_search img, .htico_search img "
          )
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      }
    });

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    // $(".hcategory_trigger").click(function () {
    //   $(this).toggleClass("active");
    //   $(".hcategory_menu").toggleClass("open");
    // });

    // $(document).click(function (e) {
    //   if (!$(e.target).is(".hcategory_trigger")) {
    //     if ($(".hcategory_menu").hasClass("open")) {
    //       $(".hcategory_menu").removeClass("open");
    //       $(".hcategory_trigger").removeClass("active");
    //     }
    //   }
    // });

    /* Mobile header menu */
    $(".hcategory_selected_text").click(function () {
      $(this).toggleClass("active");
      $(".hcategory_menu").toggleClass("open");
    });

    $(document).click(function (e) {
      if (!$(e.target).is(".hcategory_selected_text")) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".hcategory_selected_text").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });

    /*this.getOptions.bind(this,"mill");*/
    /* this.getSearchProductList(); */

    setTimeout(function () {
      var gtmID =
        typeof cookie.load("gtm_id") !== null ? cookie.load("gtm_id") : "";
      if (gtmID) {
        const tagManagerArgs = {
          gtmId: gtmID,
        };
        TagManager.initialize(tagManagerArgs);
      }
    }, 1000);
  }

  componentDidUpdate() {}

  getSearchProductList() {
    var orderOutletIdtext = cookie.load("orderOutletId");
    var addquery_txt = "";
    if (
      typeof orderOutletIdtext !== undefined &&
      typeof orderOutletIdtext !== "undefined" &&
      orderOutletIdtext !== ""
    ) {
      addquery_txt = "&outletId=" + orderOutletIdtext;
    } else {
      addquery_txt = "&outletId=" + defaultoutlet;
    }
    var defaultAvilablityId = cookie.load("defaultAvilablityId");

    if (
      typeof defaultAvilablityId !== undefined &&
      typeof defaultAvilablityId !== "undefined" &&
      defaultAvilablityId !== ""
    ) {
      addquery_txt += "&availability=" + cookie.load("defaultAvilablityId");
    }
    showLoader("header-search-sec", "class");
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A" +
          addquery_txt
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;

                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      main_category_id:
                        subCatArr[sctkey].pro_cate_main_category_id,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                    });
                  }
                }
              }
            }
          }
        }
        if (
          this.props.match.path === "/products/:slugType/:slugValue" ||
          this.props.match.path === "/products"
        ) {
          this.props.sateValChange("productlist", searchResult);
        }

        this.setState(
          {
            searchProResult: searchResult,
            selectedProResult: searchResult,
            startclicksearch: false,
          },
          function () {
            hideLoader("header-search-sec", "class");
          }
        );
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();

    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item;
        }
      });

    // cookie.save("searchKeyWord", value, { path: "/" } );

    $("#clearSearch").show();
    if (value === "") {
      $("#clearSearch").hide();
    }

    this.setState({ selectedProResult: matches });
  };

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/menu/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      return <li className="no-pro-found">No Product found</li>;
    }
  }

  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  getOptions = (input) => {
    var searchProResult = this.state.searchProResult;
    var searchResult = [];
    /*if (Object.keys(searchProResult).length > 0) {*/
    /*searchProResult.map((loadData) =>
        searchResult.push({ value: loadData.value, label: loadData.label })
       );*/
    searchResult.push({ value: "wqewrr", label: "fish cury" });
    searchResult.push({ value: "werew3", label: "fish cury2" });
    return { options: searchResult };
    /*}*/
  };

  ViewProducts(event) {
    var productSlug = event.value;
    this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkAblBtn() {
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var actText = "Order Now";
    if (
      availability === deliveryId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Delivery";
    } else if (
      availability === pickupId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Takeaway";
    } else if (
      availability === dineinId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Dine In";
    } else if (availability === cateringId) {
      actText = "Catering";
      // } else if (availability === reservationId) {
    }

    if (
      this.props.match.path === "/reservation" ||
      this.props.match.path === "/reservation-summary" ||
      this.props.match.path === "/reservation-thankyou"
    ) {
      actText = "Reservation";
    }

    return (
      <a
        href="/"
        onClick={this.closepopup.bind(this)}
        className="hordertype_btn"
        title={actText}
      >
        {actText}
      </a>
    );
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (this.state.setAvilablityId !== "") {
      if (this.state.setAvilablityId === avlType) {
        clsTxt += "active";
      }
    } else {
      if (
        (availability == avlType || this.state.setAvilablityId === avlType) &&
        orderOutletId !== "" &&
        orderOutletId !== undefined
      ) {
        clsTxt += "active";
      } else if (
        availability == avlType &&
        (avlType === cateringId || avlType === reservationId)
      ) {
        clsTxt += "active";
      }
    }

    return clsTxt;
  }

  checkActiveDivMbl(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <Link to={"/myaccount"} className="myacunt_act" title="My Account">
            {" "}
            My Account{" "}
          </Link>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl, address, latitude, longitude) {
    if (mapcountry === "sg") {
      if (type === 1) {
        $("#postalcode").val(pstVl);
      } else {
        $("#postalcode1").val(pstVl);
      }
    } else {
      if (typeof address === "string") {
        if (type === 1) {
          $("#pac-input").val(address);
        } else {
          $("#pac-input").val(address);
        }
        this.setState({
          deliveryAddress: address,
          deliveryLat: latitude,
          deliveryLon: longitude,
        });
      } else {
        $("#pac-input").val("");
        this.setState({
          deliveryAddress: "",
          deliveryLat: "",
          deliveryLon: "",
        });
      }
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => {
        var displayAddress = true;
        if (mapcountry === "sg") {
          if (
            addr.latitude !== "" &&
            addr.latitude !== null &&
            addr.longitude !== "" &&
            addr.longitude !== null
          ) {
            displayAddress = true;
          } else {
            displayAddress = false;
          }
        }
        if (displayAddress === true) {
          return (
            <div className="address_linfo" key={typeTxt + "-" + index}>
              <div className="custom_radio">
                <input
                  type="radio"
                  name={"address_chk" + typeTxt}
                  value={addr.postal_code}
                  className="address_chk"
                  onChange={this.changPostalValue.bind(
                    this,
                    typeTxt,
                    addr.postal_code,
                    addr.address,
                    addr.latitude,
                    addr.longitude
                  )}
                />
                <span>
                  {addressFormat(
                    addr.unit_code,
                    addr.unit_code2,
                    addr.address,
                    addr.country,
                    addr.postal_code
                  )}
                </span>
              </div>
            </div>
          );
        }
      });

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced;
        var slotType = this.state.slotType;
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = "";
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot;
          orderSlotTxt = this.state.seleted_ord_slotTxt;
          orderSlotStrTime = this.state.seleted_ord_slot_str;
          orderSlotEndTime = this.state.seleted_ord_slot_end;
        }
        cookie.save("isAdvanced", isAdvanced, { path: "/" });
        cookie.save("slotType", slotType, { path: "/" });
        cookie.save("orderSlotVal", orderSlotVal, { path: "/" });
        cookie.save("orderSlotTxt", orderSlotTxt, { path: "/" });
        cookie.save("orderSlotStrTime", orderSlotStrTime, { path: "/" });
        cookie.save("orderSlotEndTime", orderSlotEndTime, { path: "/" });
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }
        cookie.save(
          "orderOutletPhoneNumber",
          orderInfoData["orderOutletPhoneNumber"],
          {
            path: "/",
          }
        );
        cookie.save("orderOutletEmail", orderInfoData["orderOutletEmail"], {
          path: "/",
        });

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });
        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });
        cookie.save("outletchosen", orderInfoData["defaultAvilablityId"], {
          path: "/",
        });

        if (this.state.deliveryLat !== "" && this.state.deliveryLon !== "") {
          cookie.save("latitude", this.state.deliveryLat, { path: "/" });
          cookie.save("longitude", this.state.deliveryLon, { path: "/" });
        }

        $.magnificPopup.close();
        if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" });
          window.location.href = "/";
        } else if (cookie.load("redirectfavourite") === "Yes") {
          cookie.remove("redirectfavourite", { path: "/" });
          this.props.history.push("/favourite");
        } else if (
          this.props.match.path === "/menu" ||
          this.props.match.path === "/menu/:slugType/:slugValue" ||
          this.props.match.path === "/menu/:slugType/:slugValue/:proValue"
        ) {
          this.props.history.push("/refpage" + this.props.match.url);
        } else {
          this.props.history.push("/menu");
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  goBackSlot(e) {
    e.preventDefault();
    var backSection = "";
    if (this.state.seletedAvilablityId === deliveryId) {
      backSection = "#delevery-postcode-popup";
    } else {
      backSection = "#takeaway-popup";
    }
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: backSection,
      },
      type: "inline",
    });
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var liTopCls = "htico_search";
    var scearDivTopCls = "hsearch_sec";
    var triggerActCls = "hsearch_trigger";
    var searchResultCls = "search_result";
    var searchIconcls = "search_i_icon";
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl";
      scearDivTopCls = "hsearch_sec_mbl";
      triggerActCls = "hsearch_trigger_mbl";
      searchResultCls = "search_result_mbl";
      searchIconcls = "fa fa-search";
    }
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      var client_theme = settingsArr[0].result_set.client_theme;
      return (
        <>
          {/* <li className={liTopCls}> */}
          {/* {(defaultAvilTyId === cateringId ||
			  defaultAvilTyId === reservationId) && (
			  <a
				href="/"
				data-effect="mfp-zoom-in"
				className="hsearch_trigger_dis disbl_href_action"
				title="Search"
			  >
				&nbsp;
			  </a>
			)} */}

          {/* {cookie.load("orderOutletId") !== undefined &&
			  defaultAvilTyId !== cateringId &&
			  defaultAvilTyId !== reservationId && ( */}
          <a href="/" className={triggerActCls} title="Search">
            {(() => {
              if (client_theme == 0) {
                return <img src={loupe} alt="Search" />;
              } else if (client_theme == 1) {
                return <img src={loupe} alt="Search" />;
              } else if (client_theme == 2 || client_theme == 4) {
                return <img src={loupe_dark} alt="Search" />;
              } else {
                return <img src={loupe} alt="Search" />;
              }
            })()}
          </a>
          {/* )} */}

          {/* {cookie.load("orderOutletId") === undefined &&
			  defaultAvilTyId !== cateringId &&
			  defaultAvilTyId !== reservationId && (
				<a
				  href="#order-popup"
				  data-effect="mfp-zoom-in"
				  className="open-popup-link"
				  title="Search"
				>
				  <img src={loupe} alt="Search" />
				</a>
			  )} */}

          <div className={scearDivTopCls}>
            <div className="input-sec header-search-sec">
              <input
                type="text"
                name="searchkey"
                className="productsearch"
                id={"productsearch_" + typeTxt}
                placeholder="Search..."
                onKeyUp={this.searchProKeyPress}
              />

              <a
                href="/"
                id="clearSearch"
                onClick={this.clearSearchFun.bind(this)}
                className="search_text_clear disbl_href_action"
              >
                X
              </a>
            </div>

            <ul className={searchResultCls} id={searchResultCls}>
              {this.loadProSearchList()}
            </ul>
          </div>
        </>
      );
    }
  }

  startQRScan() {
    this.setState({ dine_in_click: "Yes" });
    $.magnificPopup.close();
    this.props.history.push("/scanqrcode");
  }

  handleScan = (data) => {
    if (data) {
      // cookie.save('orderTableNo', data,{ path: '/' });
      showLoader("dinein_popup_item", "class");
      this.setState({ dine_in_click: "No", table_number: data });
      // let table_no = data;
      this.findTable();
    }
  };
  handleError = (err) => {
    this.setState({ dine_in_click: "No" });
    $.magnificPopup.close();
  };

  findTable() {
    showLoader("dinein_popup_item", "class");

    var table_number = this.state.table_number;

    if (table_number !== "") {
      cookie.save("orderTableNo", table_number, { path: "/" });

      var postObject = {
        app_id: appId,
        tm_table_number: table_number,
      };
      this.props.getTableAvailability(qs.stringify(postObject));
    } else {
      hideLoader("dinein_popup_item", "class");
      showAlert("Error", "Scan QR or Enter Table Number to Continue!");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  HandleTableNumberchng = (event) => {
    // this.findTable(event.target.value);
    this.setState({ dine_in_click: "No", table_number: event.target.value });
  };

  dineinOutlet(outletDetails) {
    $.magnificPopup.close();

    var outlet_name = outletDetails.outlet_name;
    var outlet_id = outletDetails.outlet_id;
    var outlet_unit_number1 = outletDetails.outlet_unit_number1;
    var outlet_unit_number2 = outletDetails.outlet_unit_number2;
    var outlet_address_line1 = outletDetails.outlet_address_line1;
    var outlet_address_line2 = outletDetails.outlet_address_line2;
    var outlet_postal_code = outletDetails.outlet_postal_code;
    var outlet_dine_tat = outletDetails.outlet_dine_tat;

    if (outlet_unit_number1 != "" && outlet_unit_number2 != "") {
      var unitTxt = ", #" + outlet_unit_number1 + "-" + outlet_unit_number2;
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        unitTxt +
        ", " +
        CountryTxt +
        " " +
        outlet_postal_code;
    } else if (outlet_unit_number1 != "" || outlet_unit_number2 != "") {
      var unitTxt =
        outlet_unit_number1 != ""
          ? ", #" + outlet_unit_number1
          : ", #" + outlet_unit_number2;
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        unitTxt +
        ", " +
        CountryTxt +
        " " +
        outlet_postal_code;
    } else {
      var orderHandled =
        outlet_name +
        " " +
        outlet_address_line1 +
        " " +
        outlet_address_line2 +
        ", " +
        CountryTxt +
        " " +
        outlet_postal_code;
    }

    this.setState({ orderHandled: orderHandled });
    cookie.save("defaultAvilablityId", dineinId, { path: "/" });
    cookie.save("orderOutletId", outlet_id, { path: "/" });
    cookie.save("orderOutletName", outlet_name, { path: "/" });
    cookie.save("orderPostalCode", outlet_postal_code, { path: "/" });
    cookie.save("orderTAT", outlet_dine_tat, { path: "/" });
    cookie.save("orderHandled", orderHandled, { path: "/" });
    cookie.save("orderHandledByText", orderHandled, { path: "/" });
    if (
      this.props.match.path ===
      "/menu/:slugType/:slugValue/:proValue/:tableNumber"
    ) {
      this.props.history.push(
        "/refpage" +
          "/menu/" +
          this.props.match.params.slugType +
          "/" +
          this.props.match.params.slugValue +
          "/" +
          this.props.match.params.proValue
      );
    } else if (this.props.match.path === "/menu") {
      window.location.reload();
    } else {
      this.props.history.push("/menu");
    }
  }

  availabilityList() {
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      var settingsRes = settingsArr[0].result_set.availability;
      var client_theme = settingsArr[0].result_set.client_theme;
      if (settingsRes.length > 0) {
        return settingsRes.map((item, index) => {
          return (
            <li key={index}>
              <a
                href={void 0}
                onClick={this.setAvailabilityFun.bind(
                  this,
                  item.availability_id
                )}
                className={this.checkActiveDivHd(item.availability_id)}
              >
                {(() => {
                  if (item.availability_id === deliveryId) {
                    return (
                      <div className="header-ordernow-single-img">
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={fbscooter}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={fbscooterw}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (
                            client_theme == 1 ||
                            client_theme == 3 ||
                            client_theme == 4
                          ) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={fbscooterw}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={fbscooter}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={fbscooter}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={fbscooterw}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === pickupId) {
                    return (
                      <div className="header-ordernow-single-img">
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={takeawayImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={takeawaywImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (
                            client_theme == 1 ||
                            client_theme == 3 ||
                            client_theme == 4
                          ) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={takeawaywImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={takeawayImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={takeawayImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={takeawaywImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === dineinId) {
                    return (
                      <div className="header-ordernow-single-img">
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={dineImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={dinewImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (
                            client_theme == 1 ||
                            client_theme == 3 ||
                            client_theme == 4
                          ) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={dinewImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={dineImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={dineImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={dinewImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === reservationId) {
                    return (
                      <div className="header-ordernow-single-img">
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={calenderImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={calenderwImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (
                            client_theme == 1 ||
                            client_theme == 3 ||
                            client_theme == 4
                          ) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={calenderwImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={calenderImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={calenderImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={calenderwImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === cateringId) {
                    return (
                      <div className="header-ordernow-single-img">
                        {(() => {
                          if (client_theme == 0) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={cateringImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={cateringwImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (
                            client_theme == 1 ||
                            client_theme == 3 ||
                            client_theme == 4
                          ) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={cateringwImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={cateringImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          } else if (client_theme == 2) {
                            return (
                              <>
                                {" "}
                                <img
                                  className="order_type_img"
                                  src={cateringImg}
                                  alt={item.availability_name}
                                />
                                <img
                                  className="order_type_imgwt"
                                  src={cateringwImg}
                                  alt={item.availability_name}
                                />{" "}
                              </>
                            );
                          }
                        })()}
                      </div>
                    );
                  }
                })()}

                <h3>{item.availability_name}</h3>
              </a>
            </li>
          );
        });
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "address") {
      if (value !== "") {
        var addressdetails = value.split("~~");
        this.setState(
          {
            deliveryAddress: addressdetails[0],
            deliveryLat: addressdetails[1],
            deliveryLon: addressdetails[2],
          },
          function () {
            $("#location-error").hide().html("");
          }
        );
      }
    }
  };

  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    /* For Advanced Slot Start */
    var settingsArr = this.props.globalsettings;
    var advancedTimeslotEnable = "0";
    var settingArray = [];
    var settingsRes = [];
    if (Object.keys(settingsArr).length > 0) {
      var settingsRes = settingsArr[0].result_set.availability;
    }
    var settingsAnonymousCustomer = "";
    if (Object.keys(settingsArr).length > 0) {
      var settingsAnonymousCustomer =
        settingsArr[0].result_set.client_anonymous_customer_login_enable;
    }
    var client_theme = "";
    var meta_title = "";
    var meta_desc = "";
    var meta_keywords = "";
    var client_country_id = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        advancedTimeslotEnable =
          settingsArr[0].result_set.client_advanced_timeslot_enable;
        settingArray = settingsArr[0].result_set;
        client_theme = settingArray.client_theme;
        meta_title = settingArray.client_ninja_pro_meta_title;
        meta_desc = settingArray.client_ninja_pro_meta_desc;
        meta_keywords = settingArray.client_ninja_pro_meta_keywords;
        client_country_id = settingArray.client_country_id;
        if (client_theme === "2") {
          if ($("body.whitetheme").length === 0) {
            $("body").addClass("whitetheme");
          }
        }
        if (client_theme === "3") {
          if ($("body.whitetheme").length === 0) {
            $("body").addClass("orange-theme");
          }
        }
        if (client_theme === "4") {
          if ($("body.whitetheme").length === 0) {
            $("body").addClass("green-theme");
          }
        }
      }
    }

    return (
      <header>
        <Helmet>
          <title>{companyname}</title>
          {meta_title !== "" && <meta name="title" content={meta_title} />}
          {meta_desc !== "" && <meta name="description" content={meta_desc} />}
          {meta_keywords !== "" && (
            <meta name="keywords" content={meta_keywords} />
          )}
          {meta_title !== "" && (
            <meta property="og:title" content={meta_title} />
          )}
          {meta_desc !== "" && (
            <meta property="og:description" content={meta_desc} />
          )}
          <meta
            property="og:image"
            content={"/img/" + foldername + "/ogimage.png"}
          />
          <link rel="icon" href={"/img/" + foldername + "/favicon.ico"} />
          {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2" && (
            <meta
              name="facebook-domain-verification"
              content="0z3raqfa5uql5br8x6jcak1p4q7qin"
            />
          )}
        </Helmet>
        <div className="header-top-cls">
          <div className="container-full">
            <div className="logo-main-section">
              {/* trigger_menunav_act */}
              <div className="menu_icon ">
                {/*  <span className="icon-bar icon-bar1"></span>
                <span className="icon-bar icon-bar2"></span>
                <span className="icon-bar icon-bar3"></span> */}
              </div>
              <div className="mobile-login">
                <a href="#" className="controller-nav">
                  <img src={avicon} alt="avatar" />
                </a>
                <ul className="mobile-login-list">
                  <li>
                    <a
                      href="#login-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link htico_sign"
                      title="Login"
                      onClick={(e) => {
                        e.preventDefault();
                        $.magnificPopup.open({
                          items: {
                            src: "#login-popup",
                          },
                          type: "inline",
                        });
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      href="#signup-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link"
                      title="Sign up"
                    >
                      Register
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile-menu">
                <div className="mobile-menu-header">
                  <div className="mobile-menu-close">
                    <span id="close_mobile_menu"></span>
                  </div>
                </div>
                {this.state.menudata && (
                  <div className="mobile-menu-body">
                    <ul className="menu">
                      {this.state.menudata && this.listMainNavigation()}
                      {cookie.load("UserId") ? (
                        <>
                          <li
                            style={{
                              display:
                                this.state.menudata !== "" &&
                                typeof this.state.menudata !== undefined &&
                                typeof this.state.menudata !== "undefined" &&
                                this.state.menudata.length > 0
                                  ? ""
                                  : "none",
                            }}
                          >
                            <Link
                              className={
                                this.props.match.path === "/myaccount" ||
                                this.props.match.path === "/myorders" ||
                                this.props.match.path === "/rewards" ||
                                this.props.match.path === "/mypromotions"
                                  ? "htico_sign active"
                                  : "htico_sign"
                              }
                              to={"/myaccount"}
                              title="My Account"
                            >
                              <i /> <span>My Account</span>
                            </Link>
                          </li>
                          <li
                            style={{
                              display:
                                this.state.menudata !== "" &&
                                typeof this.state.menudata !== undefined &&
                                typeof this.state.menudata !== "undefined" &&
                                this.state.menudata.length > 0
                                  ? ""
                                  : "none",
                            }}
                          >
                            <Link
                              className="htico_sign"
                              to="/logout"
                              title="Logout"
                            >
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <li
                          style={{
                            display:
                              this.state.menudata !== "" &&
                              typeof this.state.menudata !== undefined &&
                              typeof this.state.menudata !== "undefined" &&
                              this.state.menudata.length > 0
                                ? ""
                                : "none",
                          }}
                        >
                          <a
                            href="#login-popup"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link htico_sign"
                            title="Login"
                            onClick={(e) => {
                              e.preventDefault();
                              $("#close_mobile_menu").trigger("click");
                              $.magnificPopup.open({
                                items: {
                                  src: "#login-popup",
                                },
                                type: "inline",
                              });
                            }}
                          >
                            <span>Login</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>

              <div
                className={
                  appId !== "9C56E15B-CEE2-4A23-B333-09C458896BC3"
                    ? "logo"
                    : "logo square-image"
                }
              >
                <Link to={"/"} title={companyname}>
                  <img src={mainLogo} alt={companyname} />
                </Link>
              </div>
              {this.state.menudata && (
                <div className="hmenu_sec">
                  <ul className="hmenu_list desktop_hmenu_list">
                    {this.listMainNavigation()}
                  </ul>
                </div>
              )}

              <div className="hmenu-login-section">
                <ul className="hmenu-login-act">
                  {cookie.load("UserId") ? (
                    <>
                      <li
                        className="hsign_sec"
                        style={{
                          display:
                            this.state.menudata !== "" &&
                            typeof this.state.menudata !== undefined &&
                            typeof this.state.menudata !== "undefined" &&
                            this.state.menudata.length > 0
                              ? ""
                              : "none",
                        }}
                        key="2"
                      >
                        <Link
                          className={
                            this.props.match.path === "/myaccount" ||
                            this.props.match.path === "/myorders" ||
                            this.props.match.path === "/rewards" ||
                            this.props.match.path === "/mypromotions"
                              ? "htico_sign active"
                              : "htico_sign"
                          }
                          to={"/myaccount"}
                          title="My Account"
                        >
                          <i /> <span>My Account</span>
                        </Link>
                        <span>|</span>
                        <Link
                          className="htico_sign"
                          to="/logout"
                          title="Logout"
                        >
                          <i /> <span>Logout</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <li
                      className="hsign_sec"
                      style={{
                        display:
                          this.state.menudata !== "" &&
                          typeof this.state.menudata !== undefined &&
                          typeof this.state.menudata !== "undefined" &&
                          this.state.menudata.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <a
                        href="#login-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link htico_sign"
                        title="Login"
                        onClick={(e) => {
                          e.preventDefault();
                          $.magnificPopup.open({
                            items: {
                              src: "#login-popup",
                            },
                            type: "inline",
                          });
                        }}
                      >
                        <span>Login</span>
                      </a>
                      <span>|</span>
                      <a
                        href="#signup-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link"
                        onClick={(e) => {
                          e.preventDefault();
                          $.magnificPopup.open({
                            items: {
                              src: "#signup-popup",
                            },
                            type: "inline",
                          });
                        }}
                        title="Sign up"
                      >
                        {" "}
                        <span>Sign Up</span>
                      </a>
                    </li>
                  )}
                  {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2" && (
                    <li className="hordertype_sec home">
                      <a
                        href="https://jusignatures.com/"
                        target="blank"
                        class="hordertype_btn"
                        title="Home"
                      >
                        Home
                      </a>
                    </li>
                  )}
                  <li className="hordertype_sec">{this.checkAblBtn()}</li>
                  {cookie.load("orderOutletId") !== "" &&
                    cookie.load("orderOutletId") !== undefined && (
                      <li className="htico_search">
                        {this.searBlkFun("desktop")}
                      </li>
                    )}
                  <li
                    className={
                      this.props.match.path === "/favourite"
                        ? "hcart_like active"
                        : "hcart_like"
                    }
                  >
                    <Link
                      to={"/favourite"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!cookie.load("UserId")) {
                          window.$.magnificPopup.open({
                            items: {
                              src: "#login-popup",
                            },
                            type: "inline",
                          });
                        } else {
                          this.props.history.push("/favourite");
                        }
                      }}
                    >
                      {(() => {
                        if (client_theme == 0) {
                          return <img src={cartLike} alt="Favourite" />;
                        } else if (client_theme == 1) {
                          return <img src={cartLike} alt="Favourite" />;
                        } else if (client_theme == 2) {
                          return <img src={cartLikeDark} alt="Favourite" />;
                        } else {
                          return <img src={cartLike} alt="Favourite" />;
                        }
                      })()}

                      <img
                        src={cartLikeActive}
                        alt="Favourite"
                        title="Favourite"
                      />
                    </Link>
                  </li>
                  {this.props.match.path !== "/package" &&
                    this.props.match.path !== "/package/checkout" && (
                      <li className="htico_cart">
                        <CartSideBar
                          {...this.props}
                          headerState={this.state}
                          prpSateValChange={this.props.sateValChange}
                          setdateTimeFlg={this.setdateTimeFlg}
                          pageName="header"
                        />
                      </li>
                    )}
                  {this.props.match.path === "/package" && (
                    <li className="htico_cart catering_htico_cart">
                      <Link to="/package" className="card_icon">
                        <img src={shoppingBag} alt="cart" />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-order-now">
          <div className="mobile-order-lhs">
            {cookie.load("UserId") ? (
              <>
                <Link
                  className={
                    this.props.match.path === "/myaccount" ||
                    this.props.match.path === "/myorders" ||
                    this.props.match.path === "/rewards" ||
                    this.props.match.path === "/mypromotions"
                      ? "htico_sign active"
                      : "htico_sign"
                  }
                  to={"/myaccount"}
                  title="My Account"
                >
                  <i /> <span>My Account</span>
                </Link>{" "}
                <Link to="/logout" title="Logout">
                  <span>Logout</span>
                </Link>
              </>
            ) : (
              <>
                <a
                  href="#login-popup"
                  data-effect="mfp-zoom-in"
                  title="Login"
                  onClick={(e) => {
                    e.preventDefault();
                    $.magnificPopup.open({
                      items: {
                        src: "#login-popup",
                      },
                      type: "inline",
                    });
                  }}
                >
                  <span>Login</span>
                </a>
                <a
                  href="#signup-popup"
                  data-effect="mfp-zoom-in"
                  title="Login"
                  onClick={(e) => {
                    e.preventDefault();
                    $.magnificPopup.open({
                      items: {
                        src: "#signup-popup",
                      },
                      type: "inline",
                    });
                  }}
                >
                  <span>Signup</span>
                </a>
              </>
            )}
          </div>
          {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2" && (
            <div className="mobile-order-rhs">
              <a
                href="https://jusignatures.com/"
                target="blank"
                class="hordertype_btn"
                title="Home"
              >
                Home
              </a>
            </div>
          )}
          <div className="mobile-order-rhs">{this.checkAblBtn()}</div>
        </div>

        {/* mobile_accountdel_body - start */}

        {/* mobile_accountdel_body - end */}

        {/* currenturl.includes(substring) && (
          <div className="hcategory_sec">
            <a href="/" className="hcategory_selected_text disbl_href_action">
              {showCatryName} <span className="hcategory_trigger"></span>
            </a>
            <div className="hcategory_menu ">
              <MenuNavigationmob />
            </div>
          </div>
        ) */}

        <div
          className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">
            Nice! Products added to your cart
          </p>{" "}
        </div>

        <div
          className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-error-msg">Something went wrong</p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-common-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-success-msg">
            Nice! Products added to your cart
          </p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-common-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-error-msg">Something went wrong</p>{" "}
        </div>

        {/* login popup */}

        <div id="login-popup" className="mfp-hide popup_sec login-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3> Member Login </h3>
              <p>Sign in or Join Now</p>
            </div>
            <div className="full-login-new-body">
              {settingsAnonymousCustomer === "1" && (
                <div className="form-group">
                  <div className="login_pop_sub login_in_skip_guest">
                    <button
                      type="button"
                      className={
                        appId === "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2"
                          ? "btn btn_black btn_minwid guestcheckout_submit skip-hans-im-gluk"
                          : "btn btn_black btn_minwid guestcheckout_submit"
                      }
                      onClick={this.handleCheckoutSkip}
                    >
                      {appId === "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2"
                        ? "Submit Order"
                        : "Skip to Checkout"}
                    </button>
                  </div>
                </div>
              )}

              <div className="popup-footer">
                <FacebookLogin
                  appId={fbAppId}
                  fields="name,email,picture,first_name,last_name,birthday,gender"
                  callback={this.responseFacebook}
                  scope="public_profile,email,user_birthday"
                  cssclassName="btn btn-black  fa fa-facebook"
                  redirectUri={baseUrl}
                  isMobile={false}
                  icon="fa-facebook"
                  textButton="&nbsp; Login with Facebook"
                />
                <div className="or-seperator">
                  <span>Or Sign in with</span>
                </div>
              </div>

              <Login
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleSignin}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>
        {/* Signup popup */}
        <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3> Create an Account </h3>
              <p>Update your informations and continue</p>
            </div>
            <div className="full-login-new-body">
              {appId != "99F42644-8372-42EA-8BF0-215DF11369E1" && (
                <div className="popup-footer signup-popup-head">
                  <FacebookLogin
                    appId={fbAppId}
                    fields="name,email,picture,first_name,last_name,birthday,gender"
                    callback={this.responseFacebook}
                    scope="public_profile,email,user_birthday"
                    cssclassName="btn btn-black  fa fa-facebook"
                    redirectUri={baseUrl}
                    icon="fa-facebook"
                    textButton="&nbsp; Login with Facebook"
                  />

                  <div className="or-seperator">
                    <span>Or Register With</span>
                  </div>
                </div>
              )}
              <Signup
                fields={this.state.fieldssignup}
                onChange={this.fieldChangeSignup}
                onValid={this.handleSignup}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        {/* Forgot Password Popup */}

        <div
          id="forgot-password-popup"
          className="white-popup mfp-hide popup_sec login-popup forgot-password-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>Forgot your Password?</h3>
              <p>You can reset your Password here.</p>
            </div>
            <div className="full-login-new-body">
              <Forgotpassword
                fields={this.state.fieldsfgtpassword}
                onChange={this.fieldforgot}
                onValid={this.forgotpassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        <div
          id="guest-checkout-popup"
          className="white-popup mfp-hide popup_sec login-popup guest-checkout-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>Continue as Guest</h3>
              {/* <p>You can reset your PIN here.</p> */}
            </div>
            <div className="full-login-new-body">
              <Guestcheckout
                {...this.props}
                fields={this.state.fieldscheckout}
                onChange={this.fieldChangecheckout}
                onValid={this.handleCheckout}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        {/* Order popup - start */}
        <div
          id="order-popup"
          className={
            settingsRes.length == 2
              ? "white-popup mfp-hide popup_sec  two-popup"
              : "white-popup mfp-hide popup_sec  cafe-popup order_popup"
          }
        >
          <div className="full-login-new-header">
            <h3> Order Now</h3>
            <p>Please Choose</p>
          </div>
          <div className="full-login-new-body">
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  {this.availabilityList()}
                </ul>
                {this.state.error_order_type === 1 && (
                  <div className="postal_error">
                    <span className="error">
                      Please select any one avilablity
                    </span>
                  </div>
                )}
                {/* <div className="two-button-row common-button-row">
                  <div className="go_second">
                    <a
                      href="/"
                      onClick={this.gobackOrderFrom.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Order popup - end */}

        {/* delivery-takeaway popup - start */}
        <div
          id="delivery-takeaway-popup"
          className="white-popup mfp-hide popup_sec order_popup delivery_takeaway_popup"
        >
          <div className="full-login-new-header">
            <h3> Menu</h3>
            <p>Please Choose</p>
          </div>
          <div className="full-login-new-body">
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href="/"
                      onClick={this.setAvailabilityFun.bind(this, deliveryId)}
                      className={this.checkActiveDivHd(deliveryId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img
                          className="order_type_img"
                          src={fbscooter}
                          alt="Delivery"
                        />
                        <img
                          className="order_type_imgwt"
                          src={fbscooterw}
                          alt="Delivery"
                        />
                      </div>
                      <h3>Delivery</h3>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={this.setAvailabilityFun.bind(this, pickupId)}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img
                          className="order_type_img"
                          src={takeawayImg}
                          alt="Takeaway"
                        />
                        <img
                          className="order_type_imgwt"
                          src={takeawaywImg}
                          alt="Takeaway"
                        />
                      </div>
                      <h3>Takeaway</h3>
                    </a>
                  </li>
                </ul>
                {this.state.error_order_type === 1 && (
                  <div className="postal_error">
                    <span className="error">
                      Please select any one avilablity
                    </span>
                  </div>
                )}
                <div className="two-button-row common-button-row">
                  <div className="go_second">
                    <a
                      href="/"
                      onClick={this.gobackOrderFrom.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  {/* <div className="con_first">
                    <a
                      className="button disbl_href_action"
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        this.state.setAvilablityId
                      )}
                    >
                      Continue
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* delivery-takeaway popup - end */}

        {/* Delivery Popup - Start */}
        <div
          id="delevery-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
        >
          <div className="full-order-now-header">
            <h2>Please Select</h2>
            <p>Your Delivery Outlet</p>
          </div>
          <div className="full-order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img
                  className="outlet-scooter-img"
                  src={fbscooterw}
                  alt="Delivery"
                />
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadDeliveryOutletsList()}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                      onClick={this.selectDlyOutlet.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delivery Popup - end */}

        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="full-login-new-header">
            <h3> Delivery </h3>
            <p>Let us know Your Delivery Location</p>
          </div>

          <div className="full-login-new-body">
            <img
              className="pop-scooter-img"
              src={fbscootersvg}
              alt="Delivery"
            />

            <div className="innervmid_in">
              {cookie.load("UserId") && (
                <div className="address-list-cls address-list-main">
                  {this.userAddressList(1)}
                </div>
              )}

              {this.state.openAddress === true &&
                client_country_id !== "154" && (
                  <div className="form-group">
                    <Autocomplete
                      sateValChange={this.sateValChange}
                      mapcountry={mapcountry}
                    />
                  </div>
                )}
              {client_country_id === "154" && (
                <div className="form-group">
                  <div className="datetime_selt_lbl">
                    Enter your postal code
                  </div>
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out"
                    }
                  >
                    <input
                      type="text"
                      id="postalcode"
                      pattern="\d*"
                      maxLength="6"
                      placeholder="Postal Code"
                      className="form-control input-focus"
                      onKeyPress={(e) => this.validateIntval(e)}
                    />
                    <div className="postal_error"></div>
                  </div>
                </div>
              )}

              <div className="btn_sec">
                <div className="two-button-row">
                  <div className="go_second">
                    {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                    <a
                      href="/"
                      onClick={this.gobackOrderNow.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  <div className="con_first delivery_submit_cls">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        1,
                        deliveryId
                      )}
                      className="button button-right delivery_submit"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup - end */}

        {/*  Offline code Popup - start */}
        <div
          id="offline-popup"
          className="white-popup mfp-hide popup_sec delevery_popup offline_popup"
        >
          <div className="full-login-new-body">
            <img
              className="offline-img pop-scooter-img"
              src={offline}
              alt="Offline"
            />

            <div className="innervmid_in">
              <div className="offline-head">
                <h2>Site Is offline</h2>
                <p>Please Contact the merchant to place order directly</p>
              </div>
            </div>
          </div>
        </div>
        {/* Offline Popup - end */}

        {/* Delevery Popup error - start */}

        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="full-login-new-header">
            <h3 className="text-uppercase">Sorry </h3>
            <p>We cant find your postal code</p>
          </div>

          <div className="full-login-new-body">
            <img
              className="pop-scooter-img"
              src={iconUnhappy}
              alt="Delivery No Available"
            />

            <div className="innervmid_in">
              <form className="form_sec">
                <div className="form-group">
                  <div className="datetime_selt_lbl">
                    Enter your postal code
                  </div>
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out"
                    }
                  >
                    <label>Postal code</label>
                    <input
                      type="text"
                      id="postalcode1"
                      pattern="\d*"
                      maxLength="6"
                      className="form-control input-focus"
                      onKeyPress={(e) => this.validateIntval(e)}
                    />
                    <div className="postal_error"></div>
                  </div>
                </div>
                <div className="btn_sec delivery_submit_cls delivery_submit_div">
                  {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                  <input
                    type="button"
                    onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)}
                    className="button delivery_submit"
                    value="Continue"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide delevery_popup_datetime"
        >
          <div className="full-login-new-header">
            <h3>
              {" "}
              {this.state.seletedAvilablityId === deliveryId
                ? "Delivery"
                : "Takeaway"}{" "}
            </h3>
            <p>
              {this.state.seletedAvilablityId === deliveryId
                ? "Let us Know your Delivery Location"
                : "Let us Know your Takeaway Outlet"}
            </p>
          </div>
          <div className="full-login-new-body">
            <img className="pop-scooter-img" src={iconWin} alt="Awesome" />
            <h2 className="text-uppercase">Awesome</h2>

            {this.state.seletedAvilablityId === deliveryId ? (
              <div className="awesome_del">
                <h5>We can Deliver To Your Location !</h5>
                {mapcountry === "sg" && (
                  <input
                    type="text"
                    readOnly
                    value={this.state.orderDeliveryPostalCode}
                    className="form-control input-focus"
                  />
                )}
                <h2>{this.state.orderDeliveryAddress}</h2>
              </div>
            ) : (
              <div className="awesome_del">
                <h5>You Can Pickup From</h5>
                <h2>
                  {this.state.pickupInfo.orderOutletName}
                  <br />
                  {this.state.orderHandled}
                </h2>
              </div>
            )}

            <div className="datetime_selt_sec">
              <div className="datetime_selt_lbl">
                {this.state.seletedAvilablityId === deliveryId
                  ? "Choose Date & Time"
                  : "Choose Takeaway Date & Time"}
              </div>

              {!currenturl.includes(isCheckout) && (
                <div>
                  {advancedTimeslotEnable === "1" ? (
                    <OrderAdvancedDatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                      indutualText={false}
                    />
                  ) : (
                    <OrderdatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                    />
                  )}
                </div>
              )}

              <div className="ordrdatetime_error"></div>
            </div>

            <div className="btn_sec">
              <a
                href="/"
                className="button"
                title="Go Back"
                onClick={this.goBackSlot.bind(this)}
              >
                Go Back
              </a>
              <input
                type="button"
                onClick={this.setOrderOutletDateTimeData.bind(this)}
                className="button disbl_href_action"
                value="Continue"
              />
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} alt="Awesome" />
                  <h2 className="text-uppercase">Awesome</h2>
                  <small>We can Deliver !</small>
                </div>
                <div className="awesome_del">
                  <h5>Your Delivery Address :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* error Popup - start */}
        <div
          id="outlet-error-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_body">
                <img
                  className="warning-popup-img"
                  src={warningImg}
                  alt="Warning"
                />
                <h2 className="text-uppercase">Sorry</h2>
                <p>{"We are unavailable to take your order at the moment."}</p>
                <p>Please come back again.</p>
                <div className="alt_btns">
                  {this.state.seletedAvilablityId === pickupId ? (
                    <a
                      href="/"
                      onClick={this.gobckPkupOutletpopup.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      change outlet
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.gobckOutletpopup.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      change address
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* error Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> Takeaway </h3>
            <p>Let us Know your Takeaway Outlet</p>
          </div>
          <div className="full-login-new-body">
            <img src={takeawayImg} className="takeaway-bag" alt="Takeaway" />

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus search_outlet"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <ul className="self_outlet_inner">
                      {this.loadOutletsList()}
                    </ul>
                    {this.state.error_pickup_outlet === 1 && (
                      <>
                        <br />
                        <div className="postal_error">
                          <span className="error">
                            Please select any one outlet
                          </span>
                        </div>
                      </>
                    )}
                    <div className="takelist-btn-part common-button-row">
                      <a
                        className="button"
                        href="/"
                        onClick={this.gobackOrderNow.bind(this)}
                      >
                        Go Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="viewmenu-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> View Menu </h3>
            <p>Please choose the outlet to view the menu</p>
          </div>
          <div className="full-login-new-body">
            <img src={menuImg} alt="View Menu" />
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus search_outlet"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <ul className="self_outlet_inner">
                      {this.loadMenuOutletsList()}
                    </ul>
                    <div className="takelist-btn-part">
                      <a
                        className="button takeaway-btn-act"
                        id="takeaway-continue-link"
                        href="/"
                        onClick={this.gobackOrderNow.bind(this)}
                      >
                        Go Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/*  Dinein Popup - start */}
        <div
          id="dinein-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="full-login-new-header">
            <h3> Dine In </h3>
            <p>Let us know your dine in table number</p>
          </div>
          <div className="full-login-new-body">
            <img src={dineImg} alt="DineIn" className="dinein-plate" />
            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item dinein_popup_item">
                  {this.state.dine_in_click !== "Yes" && (
                    <button
                      className="scan-qr"
                      typ="button"
                      onClick={this.startQRScan.bind(this)}
                    >
                      <img src={qrImg} alt="QR Code" />
                      <span>Scan Your QR Code</span>
                    </button>
                  )}

                  {/* {(this.state.dine_in_click === "Yes" && <>
                      <div className="self_popup_hea_row" id="qr_code">
                        <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '312px' }}
                        />
                      </div>
                      <div>
                        <button typ="button" onClick={ this.handleError }>Cancel</button>
                      </div>
                    </>)} */}

                  {this.state.dine_in_click !== "Yes" && (
                    <div className="innervmid_in">
                      <div className="form-group">
                        <input
                          type="text"
                          id="table_number"
                          onChange={this.HandleTableNumberchng}
                          placeholder="Enter Your Table Number"
                          className="form-control input-focus"
                        />
                      </div>
                    </div>
                  )}

                  {this.state.dine_in_click !== "Yes" && (
                    <div className="takelist-btn-part btn_sec">
                      <a
                        className="button"
                        href={void 0}
                        onClick={this.gobackOrderNow.bind(this)}
                      >
                        Back
                      </a>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        className="button takeaway-btn-act"
                        onClick={this.findTable.bind(this)}
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Dinein Popup - end */}

        {/*  Coming Soon Popup - start */}
        <div
          id="comingsoon-popup"
          className="white-popup mfp-hide popup_sec comingsoon_popup"
        >
          <div className="order-body">
            <div className="comingsoon_popup_hea_row">
              <div className="comingsoon_popup_hea_col">
                <h2>COMING SOON.</h2>
              </div>
            </div>
            <div className="comingsoon_pop_row">
              <p> can you select another availability.</p>
              <a
                href="/"
                onClick={this.closepopup.bind(this)}
                className="button disbl_href_action"
                title="Go Back"
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
        {/* Coming Soon Popup - end */}

        {/*  QR Popup - start */}
        <div id="qr-popup" className="white-popup mfp-hide">
          <div className="full-login-new-header">
            <h3> qr code </h3>
            <p>Let us Scan Your QR Code</p>
          </div>

          <div className="full-login-new-body">
            <div className="qrheader">
              <img src={qrbannerImg} alt="Favourite" title="Favourite" />
            </div>
            <div className="self_pop_row">
              <div className="qr_pop_col">
                <div className="qu_pop_item">
                  <button className="scan-qr" typ="button">
                    <img
                      src={qrnewImg}
                      alt="QR Code Img"
                      title="New Customer"
                    />
                    <span>Scan Your QR Code</span>
                  </button>
                  {/*<div className="innervmid_in">
                        <div className="form-group">
                          <input type="text" id="table_number" placeholder="Enter Your Table Number" 
                          className="form-control input-focus"/>
                        </div>
                      </div>*/}
                </div>

                <div className="qu_pop_item">
                  <button className="scan-qr" typ="button">
                    <img
                      src={qrnewImg}
                      alt="QR Code Img"
                      title="Exhausting Customer"
                    />
                    <span>Scan Your QR Code</span>
                  </button>
                  {/*<div className="innervmid_in">
                          <div className="form-group">
                            <input type="text" id="table_number" placeholder="Enter Your Table Number" 
                            className="form-control input-focus"/>
                          </div>
                        </div>*/}
                </div>
              </div>
              {/*<div className="takelist-btn-part">
                        <button type="button" className="button takeaway-btn-act">Continue</button>
                      </div>*/}
            </div>
          </div>
        </div>
        {/* QR Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="full-login-new-header">
            <h3>Warning</h3>
          </div>
          <div className="full-login-new-body">
            <img className="warning-popup-img" src={warningImg} alt="Warning" />
            {this.state.switchmsg !== "" &&
            this.state.switchmsg !== "/products" ? (
              <>
                <p>
                  Switch to{" "}
                  {this.state.switchmsg[0].toUpperCase() +
                    this.state.switchmsg.slice(1)}
                  ?
                </p>
              </>
            ) : (
              <>
                <p>By switching you are about to clear your cart.</p>
                <p>Do you wish to proceed ?</p>
              </>
            )}
            <input type="hidden" id="clearcartavailability" />
            <div className="alt_btns">
              <a
                href={void 0}
                className="popup-modal-dismiss button button-left disbl_href_action"
              >
                No
              </a>
              {/* popup-modal-dismiss */}
              <a
                href={void 0}
                onClick={this.changeAvailability.bind(this)}
                className="button button-right  disbl_href_action clear_all_values"
              >
                Yes
              </a>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        <div className="mbl-menu-overly"></div>
      </header>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  var tableAvailability = Array();
  if (Object.keys(state.tableavailability).length > 0) {
    // if (state.tableavailability[0].status === "ok") {
    tableAvailability = state.tableavailability[0];
    // }
  }

  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  var product_lead_time = 0;

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      product_lead_time = resultSetArr.product_lead_time;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  return {
    globalsettings: state.settings,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    tableAvailability: tableAvailability,

    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getTableAvailability: (formPayload) => {
      dispatch({ type: GET_TABLEAVAILABILITY, formPayload });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
